import React, { Component } from 'react';

class About extends Component {

    constructor(props) {
        super(props);
        this.state = {
          users: 13000,
          projects: 14,
          infrastructures: 9,
          proposals: 3000
        }
      }

    componentDidMount() {
        const script = document.createElement("script");
    
        script.src = "assets/vendor/purecounter/purecounter.js";
        script.async = true;
    
        document.body.appendChild(script);
      }
    render() {
        return (


            <section id="about" className="about">
                <div className="container" data-aos="fade-up">

                    <div className="row justify-content-end">
                        <div className="col-lg-11">
                            <div className="row justify-content-end">

                                <div className="col-lg-3 col-md-5 col-6 d-md-flex align-items-md-stretch">
                                    <div className="count-box">
                                        <i className="bi bi-emoji-smile"></i>
                                        <span data-purecounter-start="0" data-purecounter-end={this.state.users} data-purecounter-duration="1" className="purecounter"></span>
                                        <p>Users</p>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-md-5 col-6 d-md-flex align-items-md-stretch">
                                    <div className="count-box">
                                        <i className="bi bi-award"></i>
                                        <span data-purecounter-start="0" data-purecounter-end={this.state.projects} data-purecounter-duration="1" className="purecounter"></span>
                                        <p>EC Funded Projects</p>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-md-5 col-6 d-md-flex align-items-md-stretch">
                                    <div className="count-box">
                                        <i className="bi bi-building"></i>
                                        <span data-purecounter-start="0" data-purecounter-end={this.state.infrastructures} data-purecounter-duration="1" className="purecounter"></span>
                                        <p>Research infrastructures and National Facilities</p>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-md-5 col-6 d-md-flex align-items-md-stretch">
                                    <div className="count-box">
                                        <i className="bi bi-file-bar-graph"></i>
                                        <span data-purecounter-start="0" data-purecounter-end={this.state.proposals} data-purecounter-duration="1" className="purecounter"></span>
                                        <p>Proposals</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="row">

                        <div className="col-lg-6 video-box align-self-baseline" data-aos="zoom-in" data-aos-delay="100">
                            <img src="assets/img/engineer-4915425_1920.jpeg" className="img-fluid" alt="" />
                                <a href={this.props.video} className="glightbox play-btn mb-4"></a>
                        </div>

                        <div className="col-lg-6 pt-3 pt-lg-0 content">
                            <h3>ARIA is a cloud platform for Access and Facility management</h3>
                            <p className="fst-italic">
                                Dubbed the "Office 365 for Research Infrastructures", ARIA provides a large suite of tools to manage your RI and offer your services to researchers.  
                            </p>
                            <ul>
                                <li><i className="bx bx-check-double"></i> Customisable service catalogue.</li>
                                <li><i className="bx bx-check-double"></i> Integrated moderation and reviews.</li>
                                <li><i className="bx bx-check-double"></i> GDPR compliant messaging system.</li>
                                <li><i className="bx bx-check-double"></i> Reporting and statistics.</li>
                                <li><i className="bx bx-check-double"></i> Much much more!</li>
                            </ul>
                            <p>
                                Instruct-ERIC built ARIA based on years of experience delivering access to cutting edge research facilities across Europe. ARIA has since been used by EC Funded projects and national facilities, and is now the go-to solution for access provision and facility management.
                            </p>
                        </div>

                    </div>

                </div>
            </section>

        )
    }
}
export default About;

