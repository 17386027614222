import React, { Component } from 'react';
import './style.css';

class Footer extends Component {

  componentDidMount() {
    const script = document.createElement("script");

    script.src = "assets/js/main.js";
    script.async = true;

    document.body.appendChild(script);
  }

  render() {
    return (
      <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">

              <div className="col-lg-3 col-md-6">
                <div className="footer-info">
                  <h3>Instruct-ERIC</h3>
                  <p>
                    Oxford House, Parkway Court <br />
                    John Smith Drive, Oxford<br /><br />
                    <strong>Phone:</strong> +44 (0)1865 98 86 39<br />
                    <strong>Email:</strong> aria@instruct-eric.org<br />
                  </p>
                  <div className="social-links mt-3">
                    <a href="https://twitter.com/aria_access" className="twitter"><i className="bx bxl-twitter"></i></a>
                    <a href="https://www.linkedin.com/company/instruct-eric/" className="linkedin"><i className="bx bxl-linkedin"></i></a>
                    <a href="https://gitlab.com/aria-php" className="gitlab"><i className="bx bxl-gitlab"></i></a>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  <li><i className="bx bx-chevron-right"></i> <a href="#">Home</a></li>
                  <li><i className="bx bx-chevron-right"></i> <a href="https://instruct-eric.org/help/collections">Help Guides</a></li>
                  <li><i className="bx bx-chevron-right"></i> <a href="https://instruct-eric.org/content/terms-of-use">Terms of service</a></li>
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 footer-links">
                
              </div>

              <div className="col-lg-4 col-md-6 footer-newsletter">
                <a href="https://instruct-eric.org" target="_blank"><img src='/img/logos/instruct-eric-logo-noline.png' /></a>
              </div>

            </div>
          </div>
        </div>

        <div className="container">
          <div className="copyright">
            &copy; Copyright <strong><span><a href="https://instruct-eric.org" target="_blank">Instruct-ERIC</a> {new Date().getFullYear()}</span></strong>. All Rights Reserved
          </div>
          <div className="credits">
            Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
          </div>
        </div>
      </footer>

    )
  }
}
export default Footer;