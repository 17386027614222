

import './App.css';
import Header from './Components/Header';
import Hero from './Components/Hero';
import About from './Components/About';
import Footer from './Components/Footer';
import Services from './Components/Services';
import Contact from './Components/Contact';
import Testimonials from './Components/Testimonials';

function App() {

  const settings = {
    video: 'https://www.youtube.com/watch?v=_qZ3VWakcIk'
  }

  return (

    <div className="App">
    
      <Header />

      <Hero video={settings.video} />

      <main id="main">
        <About video={settings.video} />
      
        <Services />
        <Testimonials />
        <Contact />
      </main>
      <Footer />
    </div>
  );
}

export default App;
