import React, { Component } from 'react';

class Hero extends Component {
    render() {
        return (
            
          <section id="hero">
            <div className="hero-container" data-aos="fade-up" data-aos-delay="150">
              <h1>Access. Facility. Data. Community.</h1>
              <h2>Research Infrastructure and Access Management tools in the cloud</h2>
              <div className="d-flex">
                <a href="#about" className="btn-get-started scrollto">Get Started</a>
                <a href={this.props.video} className="glightbox btn-watch-video"><i className="bi bi-play-circle"></i><span>Watch Video</span></a>
              </div>
            </div>
          </section>

        )
    }
}
export default Hero;