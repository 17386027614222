import React, { Component } from 'react';

class Testimonials extends Component {
    render() {
        return (
            
          <section id="testimonials" className="testimonials">
            <div className="container" data-aos="zoom-in">
      
              <div className="testimonials-slider swiper" data-aos="fade-up" data-aos-delay="100">
                <div className="swiper-wrapper">
      
                <div className="swiper-slide">
                    <div className="testimonial-item">
                      <img src="img/people/haraldschwalbe.png" className="testimonial-img" alt=""/>
                      <h3>Professor Harald Schwalbe</h3>
                      <h4>Director of Instruct-ERIC</h4>
                      <p>
                        <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                        ARIA has developed to a highly versatile platform for European Research Infrastructures, handling access, interaction with user communities, communication, project planing and monitoring. It is adaptable to the needs of very diverse user groups and research communities. It has now a well recognized scope in European Research.
                        <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                      </p>
                    </div>
                  </div>

                  <div className="swiper-slide">
                    <div className="testimonial-item">
                      <img src="img/people/davestuart.png" className="testimonial-img" alt=""/>
                      <h3>Sir Dave Stuart</h3>
                      <h4>Director of Life Sciences, Diamond Light Source</h4>
                      <p>
                        <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                        Integrative structural biology infrastructure is powerful but can be complex to navigate for users and really tough to manage, ARIA is a real step forward and has allowed us to streamline processes in the Instruct hub without getting in the way of the science.
                        <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                      </p>
                    </div>
                  </div>
      
                  <div className="swiper-slide">
                    <div className="testimonial-item">
                      <img src="img/people/tassosperrakis.png" className="testimonial-img" alt=""/>
                      <h3>Tassos Perrakis</h3>
                      <h4>iNEXT Deputy Coordinator</h4>
                      <p>
                        <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                        ARIA has been crucial for getting a diverse set of services to the iNEXT infrastructures. The ability to customise application forms, the tools that enable transparent rapid review of applications and for reporting, are only a few examples for what ARIA allows us to achieve, setting a cornerstone for success in our Horizon2020 project!
                        <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                      </p>
                    </div>
                  </div>
      
                  <div className="swiper-slide">
                    <div className="testimonial-item">
                      <img src="img/people/leitner.jpg" className="testimonial-img" alt=""/>
                      <h3>Frauke Leitner</h3>
                      <h4>CORBEL Project Manager</h4>
                      <p>
                        <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                        We were charged with the exciting task to organize an Open Call for projects, spanning access to services and technologies offered by ten European research infrastructures. Without ARIA, the management of user applications and reviews across so many partners would have been close to impossible. Thanks to the great support by the ARIA team, we managed it successfully!
                        <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                      </p>
                    </div>
                  </div>
      
                  <div className="swiper-slide">
                    <div className="testimonial-item">
                      <img src="img/people/reinout.png" className="testimonial-img" alt=""/>
                      <h3>Reinout Raijmakers</h3>
                      <h4>Bijovet Center Managing Director</h4>
                      <p>
                        <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                        By using the access management system ARIA the EU project iNEXT was able to very rapidly start providing access to their facilities.
                        <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                      </p>
                    </div>
                  </div>
      
                </div>
                <div className="swiper-pagination"></div>
              </div>
      
            </div>
          </section>


        )
    }
}
export default Testimonials;