import React, { Component } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Contact extends Component {


  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      message: '',
      subject: ''
    }
  }

  handleSubmit(event) {
    event.preventDefault();

    fetch('/backend/mailer.php', {
      method: "POST",
      body: JSON.stringify(this.state),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }).then(
      (response) => (response.json())
    ).then((response) => {
      if (response.status === 'success') {
        //alert(response.message);
        toast.success(response.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        this.resetForm()
      } else if (response.status === 'fail') {
        //alert(response.message);
        toast.error(response.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("Something went wrong", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  }


  onNameChange(event) {
    this.setState({ name: event.target.value })
  }

  onEmailChange(event) {
    this.setState({ email: event.target.value })
  }


  onSubjectChange(event) {
    this.setState({ subject: event.target.value })
  }

  onMessageChange(event) {
    this.setState({ message: event.target.value })
  }

  resetForm() {
    this.setState({ name: '', email: '', message: '', subject: '' })
  }

  render() {
    return (

      <section id="contact" className="contact">
        <div className="container" data-aos="fade-up">

          <div className=" section-title">
            <h2>Contact</h2>
            <p>Contact Us</p>
          </div>

          <div className="row">

            <div className="col-lg-6">

              <div className="row">
                <div className="col-md-12">
                  <div className="info-box">
                    <i className="bx bx-map"></i>
                    <h3>Our Address</h3>
                    <p>Oxford House, Parkway Court,
                    John Smith Drive, Oxford</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="info-box mt-4">
                    <i className="bx bx-envelope"></i>
                    <h3>Email Us</h3>
                    <p>aria@instruct-eric.org</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="info-box mt-4">
                    <i className="bx bx-phone-call"></i>
                    <h3>Call Us</h3>
                    <p>+44 (0)1865 98 86 39</p>
                  </div>
                </div>
              </div>

            </div>

            <div className="col-lg-6 mt-4 mt-lg-0">
              <form  onSubmit={this.handleSubmit.bind(this)} method="post" role="form" className="php-email-form">
                <div className="row">
                  <div className="col-md-6 form-group">
                    <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required value={this.state.name} onChange={this.onNameChange.bind(this)} />
                  </div>
                  <div className="col-md-6 form-group mt-3 mt-md-0">
                    <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required value={this.state.email} onChange={this.onEmailChange.bind(this)} />
                  </div>
                </div>
                <div className="form-group mt-3">
                  <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required value={this.state.subject} onChange={this.onSubjectChange.bind(this)} />
                </div>
                <div className="form-group mt-3">
                  <textarea className="form-control" name="message" rows="5" placeholder="Message" required value={this.state.message} onChange={this.onMessageChange.bind(this)}></textarea>
                </div>
                <div className="my-3">
                  <div className="loading">Loading</div>
                  <div className="error-message"></div>
                  <div className="sent-message">Your message has been sent. Thank you!</div>
                </div>
                <div className="text-center"><button type="submit">Send Message</button></div>
              </form>
            </div>

          </div>

        </div>
        <ToastContainer position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover />
      </section>


    )
  }
}
export default Contact;