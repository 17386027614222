import React, { Component } from 'react';

class Services extends Component {
    render() {
        return (
            
    <section id="features" className="services section-bg">
      <div className="container" data-aos="fade-up">

        <div className="section-title">
          <h2>Features</h2>
          <p>Some of ARIA's key features</p>
        </div>

        <div className="row" data-aos="fade-up" data-aos-delay="200">

          <div className="col-md-6 mt-4 mt-md-0">
            <div className="icon-box">
              <i className="bi bi-briefcase"></i>
              <h4><a href="#">Proposal and visit management</a></h4>
              <p>Receive proposals for access with a customisable access pathway, manage and report on research visits to facilities.</p>
            </div>
          </div>

          <div className="col-md-6">
            <div className="icon-box">
              <i className="bi bi-clipboard-check"></i>
              <h4><a href="#">Moderation and review</a></h4>
              <p>Easily manage multiple proposal requests with integrated moderation and reviews.</p>
            </div>
          </div>

          <div className="col-md-6 mt-4 mt-md-0">
            <div className="icon-box">
              <i className="bi bi-envelope"></i>
              <h4><a href="#">GDPR Safe Messaging</a></h4>
              <p>Keep important conversations safe using GDPR compliant messaging, on the web or right from your email client.</p>
            </div>
          </div>
          <div className="col-md-6 mt-4 mt-md-0">
            <div className="icon-box">
              <i className="bi bi-clock"></i>
              <h4><a href="#">Booking calendar</a></h4>
              <p>Manage your facility more efficiently with booking calendars, customisable training levels and more!</p>
            </div>
          </div>
          <div className="col-md-6 mt-4 mt-md-0">
            <div className="icon-box">
              <i className="bi bi-card-checklist"></i>
              <h4><a href="#">Machine workflows</a></h4>
              <p>Organise your processes with customisable machine workflows and expenditure reporting.</p>
            </div>
          </div>

          <div className="col-md-6 mt-4 mt-md-0">
            <div className="icon-box">
              <i className="bi bi-bar-chart"></i>
              <h4><a href="#">Integrated reporting</a></h4>
              <p>Generate statistics and reports for your facility, including H2020 project reports.</p>
            </div>
          </div>
          
        </div>

      </div>
    </section>


        )
    }
}
export default Services;